import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min'

Vue.use(VueSweetalert2);
Vue.use(VueRouter)
Vue.use(VueAxios, axios);
Vue.prototype.$url = "https://api.quran.sutanlab.id/"

const routes = [
  {
    path: '',
    component: () => import('@/layouts/HomeScaffold.vue'),
    children:[
      {
        path: '/',
        name:'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/quran-list',
        name : 'quran-list',
        component: () => import('@/views/Quran-list.vue'),
      },
      {
        path: '/surah/:no/:bookmark?',
        name : 'surah',
        component: () => import('@/views/Surah.vue'),
      },
      {
        path: '/bookmark-list',
        name : 'bookmark-list',
        component: () => import('@/views/Bookmark.vue'),
      },
    ]

  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
